import { useEffect, useState } from 'react';
import '@brainhubeu/react-carousel/lib/style.css';
import $ from 'jquery';
import { Toaster } from 'react-hot-toast';

window.$ = $;

function getChildIndex(node) {
    return Array.prototype.indexOf.call(node.parentNode.childNodes, node);
}

function App() {
    const [sliderVal, setSliderVal] = useState(0);
    var dist_top = 0;

    const scrollTo = (dest) => {        
        console.log(dest);
        var offset = window.innerWidth <= 760 ? 0 : 55;
        window.TweenMax.to('html,body', 0.6, {
            scrollTop: ($(dest).offset().top - dist_top) + offset,
            ease: window.Power4.easeInOut
        });
    };

    const handleScroll = () => {
        var mobile = window.innerWidth <= 760;
        const sections = document.querySelectorAll('.section');

        sections.forEach(section => {
            var menu_item = mobile 
                ? document.querySelector('.mobile-button[data-section="' + section.getAttribute('data-section') + '"]')
                : document.querySelector('.panel-button[data-section="' + section.getAttribute('data-section') + '"]');

            if(menu_item) {
                var in_view = mobile 
                    ? (window.innerHeight / 2 > section.getBoundingClientRect().top && window.innerHeight / 2 < section.getBoundingClientRect().bottom)
                    : (section.getBoundingClientRect().top < dist_top && section.getBoundingClientRect().top + section.getBoundingClientRect().height > dist_top);
                
                if(in_view){
                    menu_item.classList.add("active");
                    var center = (window.innerWidth / 2) - (128 * getChildIndex(menu_item)) - 64;
                    document.querySelector('.mobile-buttons').style.left = center + 'px';
                } else {
                    menu_item.classList.remove("active");
                }
            }
        });
    };

    const handleResize = () => {
        var mobile = window.innerWidth <= 760;

        $('.panel-left').css({ left: (window.innerWidth / 2) - window.innerHeight * 0.75 });
        $('.panel-right').css({ left: (window.innerWidth / 2) + window.innerHeight * 0.45 });

        if(mobile){
            dist_top = 0;
        } else {
            var panel = document.querySelector('.panel');
            var panel_height = panel.getBoundingClientRect().height;
            dist_top = ((window.innerHeight - panel_height) / 2) + (window.innerWidth / 45);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, true);
        window.addEventListener('resize', handleResize, true);

        var center = (window.innerWidth / 2) - 64;
        document.querySelector('.mobile-buttons').style.left = center + 'px';

        handleResize();
    }, []);

    return (
        <div className="App">
            <Toaster containerStyle={{}} toastOptions={{ style: { color: '#000', fontWeight: 'bold' } }} />
            
            <div className="mask desktop-only"></div>

            <div className="desktop-only panel-container">
                <div className="panel desktop-only">
                    <div className="logo">
                        <img src="/images/logo.png" alt="Logo" />
                    </div>

                    <div className="panel-left">
                        <div className="panel-button button-1" onClick={() => window.open('https://www.muzeverse.com.tr')} data-section="welcome"></div>
                        <div className="panel-button button-2" onClick={() => window.open('https://www.muzeverse.com.tr')} data-section="vision"></div>
                        <div className="panel-button button-3" onClick={() => window.open('https://www.muzeverse.com.tr')} data-section="universe"></div>
                        <div className="panel-button button-7" onClick={() => window.open('mailto:info@vrfuture.io')} data-section="contact"></div>
                    </div>

                    <div className="panel-right">
                        <div className="panel-button button-4" onClick={() => window.open('https://www.muzeverse.com.tr')} data-section="mint"></div>
                        <div className="panel-button button-5" onClick={() => window.open('https://www.muzeverse.com.tr')} data-section="roadmap"></div>
                        <div className="panel-button button-6" onClick={() => window.open('https://www.muzeverse.com.tr')} data-section="team"></div>
                        <div className="panel-button button-8" onClick={() => window.open('https://www.muzeverse.com.tr')} data-section="faq"></div>
                    </div>

                    <div className="panel-bottom desktop-social">
                        <a href="https://x.com/vrfuturenft" target="_blank"><img src="/images/x-logo.svg" style={{ height: 24 }} alt="X" /></a>
                        <a href="https://www.instagram.com/VRFutureNFT/" target="_blank"><img src="/images/instagram.svg" style={{ height: 27 }} alt="Instagram" /></a>
                        <a href="https://www.youtube.com/c/VRFutureNFT" target="_blank"><img src="/images/youtube.svg" style={{ height: 39 }} alt="YouTube" /></a>
                        <a href="https://www.linkedin.com/company/vrfuturenft/" target="_blank"><img src="/images/linkedin_icon.svg" style={{ height: 26 }} alt="LinkedIn" /></a>
                    </div>

                    <div className="panel-border"></div>
                </div>
            </div>

            <div className="panel mobile-only">
                <div className="mobile-header">
                    <div>
                        <img src="/images/logo.png" style={{ marginTop: 8, width: 90, height: 'auto' }} alt="Logo" />
                    </div>
                    <div className="mobile-social">
                        <a href="https://x.com/VRFutureNFT" target="_blank"><img src="/images/x-logo.svg" style={{ height: 19 }} alt="X" /></a>
                        <a href="https://www.instagram.com/VRFutureNFT/" target="_blank"><img src="/images/instagram.svg" alt="Instagram" /></a>
                        <a href="https://www.youtube.com/c/VRFutureNFT" target="_blank"><img src="/images/youtube.svg" style={{ height: 30 }} alt="YouTube" /></a>
                        <a href="https://www.linkedin.com/company/vrfuturenft/" target="_blank"><img src="/images/linkedin_icon.svg" style={{ height: 20 }} alt="LinkedIn" /></a>
                    </div>
                </div>
                <div className="panel-border-mobile"></div>
            </div>
            
            <div className="mobile-bottom-panel mobile-only">
                <div className="mobile-buttons-track mobile-only">
                    <div className="mobile-buttons">
                        <div data-section="welcome" className="mobile-button button-1"></div>
                        <div data-section="vision" className="mobile-button button-2"></div>
                        <div data-section="universe" className="mobile-button button-3"></div>
                        <div data-section="mint" className="mobile-button button-4"></div>
                        <div data-section="roadmap" className="mobile-button button-5"></div>
                        <div data-section="team" className="mobile-button button-6"></div>
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="section-content">
                    <div className="mint-illustration">
                        <a href="https://muzeverse.com.tr" target="_blank">
                            <img src="/video-mobile-gif.gif" alt="Illustration" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
